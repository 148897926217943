import BaseLayer from "../BaseLayer";
import {AirportShowPoint} from "../node/bussiness/airport/airportPoint";
import {AirportShowPolygon} from "../node/bussiness/airport/airportPolygon";

const userAirportHook = () => {
    let map3dCollection, renderLayer;
    return (function() {
        // 初始化地图图层实例
        const setMap = (options) => {
            const {map3d, layerID} = options;
            map3dCollection = map3d;
            renderLayer = new BaseLayer({name: layerID || "airportShowLayer"}); // 初始化绘制层
            map3dCollection.layerManager.add(renderLayer); // 添加到地图中
        };
        // 处理绘制图层数据并开始绘制
        const handlingAirportData = (options) => {
            const {data} = options;
            data && data.map(t => {
                if (t.content && t.content.coordinates) {
                    const type = t.content.type;
                    const id = t.id;
                    // 绘制之前先查找一次有无当前id的实体，若有则不需要进行绘制
                    let entity = getEntityById(id);
                    if (!entity) {
                        const coordinates = t.content.coordinates;
                        if (type === "Point") {
                            const position = Cesium.Cartesian3.fromDegrees(...coordinates);
                            createAirportPoint({id, name: t.airportName, position});
                        }
                        if (type === "Polygon") {
                            let positions = [];
                            coordinates && coordinates[0].map(t => {
                                const position = Cesium.Cartesian3.fromDegrees(...t);
                                positions.push(position);
                            });
                            createAirportPolygon({id, name: t.airportName, positions});
                        }
                    }
                }
            });
        };
        // 绘制点状机场
        const createAirportPoint = (options) => {
            let layer = new AirportShowPoint(options);
            renderLayer && renderLayer.addNode(layer);
        };
        // 绘制多边形面状机场
        const createAirportPolygon = (options) => {
            let layer = new AirportShowPolygon(options);
            renderLayer && renderLayer.addNode(layer);
        };
        // 通过id获取对应实体
        const getEntityById = (id) => {
            if (!id) return;
            return renderLayer.getById(id);
        };
        // 单独清除当前renderLayer层的渲染实体
        const clearRenderLayer = () => {
            renderLayer && renderLayer.clear();
        };
        // 清除地图实例，图层实体
        const clearAirport = () => {
            renderLayer && renderLayer.clear();
            map3dCollection.layerManager.remove(renderLayer, true);
            renderLayer = null;
            map3dCollection = null;
        };
        return {
            setMap,
            handlingAirportData,
            createAirportPoint,
            createAirportPolygon,
            getEntityById,
            clearRenderLayer,
            clearAirport
        };
    })();
};

export default userAirportHook;