import BaseLayer from "../BaseLayer";
import BaseStationPoint from "../node/bussiness/baseStation/baseStationPoint";

const userBaseStationHook = () => {
    let map3dCollection, renderLayer, mousehover, entityId;
    let popIds = []; let popslist = [];
    return (function() {
        // 初始化地图图层实例
        const setMap = (options) => {
            const {map3d, layerID} = options;
            map3dCollection = map3d;
            renderLayer = new BaseLayer({name: layerID || "baseStationLayer"}); // 初始化绘制层
            map3dCollection.layerManager.add(renderLayer); // 添加到地图中
        };
        // 处理绘制图层数据并开始绘制
        const handlingBaseStationData = (options) => {
            const {data} = options;
            data && data.map(t => {
                if (t.content) {
                    const type = t.content.type;
                    const id = t.id;
                    // 绘制之前先查找一次有无当前id的实体，若有则不需要进行绘制
                    let entity = getEntityById(id);
                    if (!entity) {
                        const coordinates = t.content.coordinates;
                        if (type === "Point") {
                            const position = Cesium.Cartesian3.fromDegrees(...coordinates);
                            createBaseStationPoint({id, position, name: t.name});
                        }
                    }
                }
            });
        };
        // 绘制基站图标
        const createBaseStationPoint = (options) => {
            let layer = new BaseStationPoint(options);
            renderLayer && renderLayer.addNode(layer);
        };
        // 添加鼠标移动事件
        const addMouseMove = (callback) => {
            removeMouseMove();
            if (!mousehover) {
                mousehover = new Cesium.ScreenSpaceEventHandler(map3dCollection.scene.canvas);
            }
            mousehover.setInputAction(e => {
                const cartain2 = new Cesium.Cartesian2(e.endPosition.x, e.endPosition.y);
                const pickobj = map3dCollection.scene.pick(cartain2);
                if (pickobj && pickobj.id && pickobj.id.drawType === 'baseStationPoint') {
                    if (entityId != pickobj.id.id) {
                        entityId = pickobj.id.id;
                        setTimeout(() => {
                            callback && callback({id: entityId, event: e, x: 123, y: 170});
                        }, 100);
                    }
                } else {
                    entityId = null;
                    callback && callback({type: 'removePop'});
                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
        };
        // 移除鼠标移动事件
        const removeMouseMove = () => {
            if (mousehover) {
                mousehover.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
                mousehover = null;
                entityId = null;
            }
        };
        // 创建弹窗
        const createdPop = (options) => {
            rmoveAllPop();
            popIds.push(options.id);
            popslist.push({
                id: options.id,
                positions: options.positions,
                x: options.x || 0,
                y: options.y || 8
            });
            const pop = () => {
                popslist.map(item => {
                    let container = document.getElementById(item.id);
                    if (container) {
                        const entity = getEntityById(options.entityId);
                        const model = entity.root.getRootNode();
                        const position = model.getHovertipPosition(options.event);
                        container.style.left = `${position.x - item.x}px`;
                        container.style.top = `${position.y - item.y}px`;
                    }
                });
            }
            if (!options.entityId || !options.event) return;
            map3dCollection.scene.preRender.addEventListener(pop);
            options.callback && options.callback();
        };
        // 移除弹窗
        const rmoveAllPop = (callback) => {
            if (popslist.length > 0) popslist = [];
            if (popIds.length > 0) {
                if (map3dCollection.scene.preRender._listeners.length > 0) {
                    map3dCollection.scene.preRender._listeners.forEach(item => {
                        if (item && item.name == 'pop') {
                            map3dCollection.scene.preRender.removeEventListener(item);
                        }
                    });
                }
                popIds = [];
            }
            callback && callback();
        };
        // 通过id获取对应实体
        const getEntityById = (id) => {
            if (!id) return;
            return renderLayer.getById(id);
        };
        // 单独清除当前renderLayer层的渲染实体
        const clearRenderLayer = () => {
            renderLayer && renderLayer.clear();
            rmoveAllPop();
            entityId = null;
            popIds = [];
            popslist = [];
        };
        // 清除地图实例，图层实体
        const clearBaseStation = () => {
            renderLayer && renderLayer.clear();
            map3dCollection.layerManager.remove(renderLayer, true);
            removeMouseMove();
            rmoveAllPop();
            entityId = null;
            popIds = [];
            popslist = [];
            renderLayer = null;
            map3dCollection = null;
        };
        return {
            setMap,
            handlingBaseStationData,
            createBaseStationPoint,
            addMouseMove,
            removeMouseMove,
            createdPop,
            rmoveAllPop,
            getEntityById,
            clearRenderLayer,
            clearBaseStation
        }
    })();
};

export default userBaseStationHook;