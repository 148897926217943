var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      staticClass: "baseStation-pop",
      attrs: { id: _vm.id ? "baseStation-pop-" + _vm.id : "baseStation-pop" },
    },
    [
      _c("div", { staticClass: "tip-triangle" }),
      _vm._l(_vm.tipItems, function (item) {
        return _c("div", { key: item.label, staticClass: "tip-item" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t(item.label)) + "：")]),
          _c("p", [_vm._v(_vm._s(_vm.detailData[item.key]))]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }