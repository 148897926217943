<script>
import NodeMap from "../js/node/index";
import userHangarHook from '@/components/cesium/js/hooks/hangarHook';
import HangarDetails from "../components/hangarDetails";
import {getHangarDetail, getHangarPlayStream} from "@/api/hangar/index";

export default {
    components: {HangarDetails},
    data() {
        return {
            ws: null,
            hangarData: '',
            hangarShow: false,
            hangarBaseData: {},
            hangarDetailsData: {},
            hangarVideoData: {}
        }
    },
    methods: {
        // 初始化机库图层
        initHangarLayer() {
            this.$options.hangarHook = userHangarHook();
            this.$options.hangarHook.setMap({map3d: this.$options.map3d, layerID: 'hangarLayer'});
        },
        // 初始化绘制机库
        initHangar(data) {
            this.hangarData = data;
            this.$options.hangarHook && this.$options.hangarHook.handlingHangarData({data, type: 'select'});
            this.$options.renderLayer = this.$options.hangarHook.getRenderLayer();
            this.setEditModel();
        },
        // 清除实体
        clearHangar() {
            this.$options.hangarHook && this.$options.hangarHook.clearRenderLayer();
        },
        // 定位机库
        locationHangar(data) {
            if (!data) return;
            const entity = this.$options.hangarHook.getEntityById(data.id);
            if (entity) {
                // this.$options.map3d.flyTo(entity, {
                //     offset: new Cesium.HeadingPitchRange(0, -90, 4000), // 设置heading,pitch和range
                //     duration: 1.5
                // });
                this.$options.map3d.zoomTo(
                    entity,
                    new Cesium.HeadingPitchRange(0, -90, 4000)
                );
            }
        },
        // 控制对应id实体的显示隐藏状态
        changeShowHangarEntity(id, type) {
            if (!id) return;
            let entity = this.$options.hangarHook.getEntityById(id);
            if (!entity) return;
            entity.show = type ? true : false;
        },
        // 点选中机库对象
        selectedHangarNode(selectFeature) {
            const node = selectFeature.getRootNode();
            this.$EventBus.$emit('choose-hangar-data', node);
            this.openHangarPanel(node);
        },
        // 删除机库成功
        deleteHangarSuccess(data) {
            if (data.id) {
                let node = this.$options.renderLayer.getNodeById(data.id);
                if (node) this.$options.renderLayer.removeNodeById(data.id);
            }
        },
        // 关闭机库信息面板
        closeHangarPanel() {
            let operation = this.$options.map3d.interactive._current
            if (operation && operation.selectFeature && operation.selectFeature.category === NodeMap.Node.HANGAR) {
                this.$options.map3d.interactive._current.cancelSelectFeature();
            }
            this.hangarShow = false;
            this.hangarBaseData = {};
            this.hangarDetailsData = {};
            this.hangarVideoData = {};
            this.ws && this.ws.close();
            this.ws = null;
        },
        // 开启机库信息面板
        openHangarPanel(node) {
            let _this = this;
            if (node.entity.sn) {
                // 链接ws
                let wsUrl = `${window.global.VUE_APP_WS_BASE}/server/uavHangarData/${node.entity.sn}`;
                this.ws = new WebSocket(wsUrl);
                this.ws.onopen = function() {};
                this.ws.onmessage = function(evt) {
                    _this.setHangarEntity(JSON.parse(evt.data));
                }
                // 获取机库详情
                getHangarDetail({id: node.id}).then(res => {
                    if (res.data.code == 200) {
                        this.hangarBaseData = res.data.data || {};
                    } else {
                        if (this.hangarData) {
                            let hangarItemData = this.hangarData.find(t => t.id === node.id);
                            this.hangarBaseData = hangarItemData || {};
                        }
                    }
                }).catch(() => {
                    if (this.hangarData) {
                        let hangarItemData = this.hangarData.find(t => t.id === node.id);
                        this.hangarBaseData = hangarItemData || {};
                    }
                }).finally(() => {
                    this.hangarShow = true;
                });
                // 获取机库内外视频
                getHangarPlayStream({sn: node.entity.sn}).then(res => {
                    if (res.data.code === 200) {
                        this.hangarVideoData = res.data.data;
                    }
                });
            }
        },
        // 设置机库信息面板
        setHangarEntity(data) {
            this.hangarDetailsData = data;
            if (data.resonMsg) {
                if (data.resonMsg.includes('成功') || data.resonMsg.includes('success')) {
                    this.$message({
                        message: data.resonMsg,
                        type: 'success',
                        duration: 2000
                    });
                } else if (data.resonMsg.includes('失败') || data.resonMsg.includes('error')) {
                    this.$message({
                        message: data.resonMsg,
                        type: 'error',
                        duration: 2000
                    });
                } else {
                    this.$message({
                        message: data.resonMsg,
                        duration: 2000
                    });
                }
            }
        }
    },
    beforeDestroy() {
        this.$options.hangarHook && this.$options.hangarHook.clearHangar();
        this.$options.hangarHook = null;
    }
}
</script>