<template>
    <!-- 机库详情弹窗 -->
    <el-dialog class="hangarDetailsDialog" :visible.sync="show" width="870px" :modal-append-to-body="false" 
        :close-on-press-escape="false" :close-on-click-modal="false" top="15vh" :before-close="closeDetailsPop">
        <div slot="title" class="dialog_title">
            <div class="dialog_title-item" :class="{'active': showItemType === 'hangarInformation'}" 
                @click="switchTab('hangarInformation')">{{$t('hangar.hangarInformation')}}</div>
            <div class="dialog_title-item" :class="{'active': showItemType === 'droneInformation'}" 
                @click="switchTab('droneInformation')">{{$t('hangar.droneInformation')}}</div>
            <div class="dialog_title-item" :class="{'active': showItemType === 'realTimeVideo'}" 
                @click="switchTab('realTimeVideo')">{{$t('hangar.realTimeVideo')}}</div>
        </div>
        <div class="content">
            <div class="hangarInformation" v-show="showItemType === 'hangarInformation'">
                <div class="top-title">{{$t('hangar.hangarName')}}：{{baseData.name || '--'}}</div>
                <div class="information-top">
                    <div class="top-img">
                        <el-image v-if="baseData.pics" style="width: 100px; height: 100px" :src="baseData.pics" 
                            :fit="'contain'" :preview-src-list="[baseData.pics]" />
                        <img v-else class="jiku" src="/img/jiku.png" alt="">
                    </div>
                    <div class="top-msg">
                        <div class="top-msg_list">
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('hangar.model')}}</div>
                                <div class="list-item_msg">{{baseData.equipmentNumber || '--'}}</div>
                            </div>
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('mission.team')}}</div>
                                <div class="list-item_msg" :title="baseData.deptNames">{{baseData.deptNames || '--'}}</div>
                            </div>
                        </div>
                        <div class="top-msg_list">
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('hangar.workingStatus')}}</div>
                                <div class="list-item_msg free" :class="[baseData.status == 1 ? 'free' : 'working']">
                                    {{baseData.status == 1 ? $t('idle') : $t('working')}}</div>
                            </div>
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('hangar.creator')}}</div>
                                <div class="list-item_msg">{{baseData.createBy || '--'}}</div>
                            </div>
                        </div>
                        <div class="top-msg_list">
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('hangar.serialNumber')}}</div>
                                <div class="list-item_msg">{{baseData.serialNumber || '--'}}</div>
                            </div>
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('noFlyZone.createdTime')}}</div>
                                <div class="list-item_msg">{{baseData.createTime || '--'}}</div>
                            </div>
                        </div>
                        <div class="top-msg_list">
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('remark')}}</div>
                                <div class="list-item_msg" :title="baseData.remark">{{baseData.remark || '--'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="information-bottom">
                    <div class="bottom-list">
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.cabinTemperature')}}</div>
                            <div class="list_item-msg">{{detailsData.inTemperature || 0}}°C</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.ambientTemperature')}}</div>
                            <div class="list_item-msg">{{detailsData.outTemperature || 0}}°C</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.windSpeed')}}</div>
                            <div class="list_item-msg">{{detailsData.windSpeed || 0}}m/s</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.lightStripSwitch')}}</div>
                            <div class="list_item-msg">
                                {{detailsData.lightStrip == 1 ? $t('openText') : $t('closeText')}}</div>
                        </div>
                    </div>
                    <div class="bottom-list">
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.flooded')}}</div>
                            <div class="list_item-msg">
                                {{detailsData.weatherFlood == 1 ? $t('correct') : $t('deny')}}</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.cumulativeRainfall')}}</div>
                            <div class="list_item-msg">{{detailsData.rainfall || 0}}mm</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.alternateLon')}}</div>
                            <div class="list_item-msg">{{detailsData.alternateLongitude  || '--'}}</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.alternateLat')}}</div>
                            <div class="list_item-msg">{{detailsData.alternateLatitude   || '--'}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="droneInformation" v-show="showItemType === 'droneInformation'">
                <div class="top-title">{{$t('hangar.droneName')}}：{{baseData.uavName || '--'}}</div>
                <div class="information-top">
                    <div class="top-img">
                        <img class="wurenji" src="/img/wurenji.png" alt="">
                    </div>
                    <div class="top-msg">
                        <div class="top-msg_list">
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('hangar.model')}}</div>
                                <div class="list-item_msg">{{baseData.uavEquipmentNumber || '--'}}</div>
                            </div>
                        </div>
                        <div class="top-msg_list">
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('hangar.workingStatus')}}</div>
                                <div class="list-item_msg free" :class="[baseData.status == 1 ? 'working' : 'free']">
                                    {{baseData.status == 1 ? $t('working') : $t('idle')}}</div>
                            </div>
                        </div>
                        <div class="top-msg_list">
                            <div class="msg_list-item">
                                <div class="list-item_name">{{$t('hangar.serialNumber')}}</div>
                                <div class="list-item_msg">{{baseData.uavSerialNumber || '--'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="information-bottom">
                    <div class="bottom-list">
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.flightDuration')}}</div>
                            <div class="list_item-msg">{{detailsData.flyTotal || 0}}h</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.chargeCount')}}</div>
                            <div class="list_item-msg">{{detailsData.chargeTimes || 0}}</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.batteryTemperature')}}</div>
                            <div class="list_item-msg">{{detailsData.batteryTemp || 0}}°C</div>
                        </div>
                        <div class="bottom-list_item">
                            <div class="list_item-name">{{$t('hangar.electricityLevel')}}</div>
                            <div class="list_item-msg">{{detailsData.electricity || 0}}%</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="realTimeVideo" v-show="showItemType === 'realTimeVideo'">
                <div class="video_item">
                    <div id="hangar-video" class="video_cont" v-loading="!hangarInsideLiveURLPlay" 
                        :element-loading-text="$t('resourceLoading')" element-loading-spinner="el-icon-loading" 
                        element-loading-background="rgba(0, 0, 0, 1)">
                        <!-- <video v-if="hangarInsideLiveURLPlay" id="hangarInside-videoElement" 
                            class="videoElement" autoplay muted /> -->
                        <div class="videoElement" v-if="hangarInsideLiveURLPlay" ref="hangarInsideVideo"></div>
                    </div>
                    <div class="flight-name">{{$t('hangar.hangarInside')}}</div>
                    <div class="operation">
                        <i class="el-icon-full-screen" @click="hangarVideoScreen('hangarInside-videoElement')"></i>
                        <i class="el-icon-refresh" @click="hangarVideoRefresh('hangarInside')"></i>
                    </div>
                </div>
                <div class="video_item">
                    <div id="hangar-video" class="video_cont" v-loading="!hangarOutLiveURLPlay" 
                        :element-loading-text="$t('resourceLoading')" element-loading-spinner="el-icon-loading" 
                        element-loading-background="rgba(0, 0, 0, 1)">
                        <!-- <video v-if="hangarOutLiveURLPlay" id="hangarOut-videoElement" 
                            class="videoElement" autoplay muted /> -->
                        <div class="videoElement" v-if="hangarOutLiveURLPlay" ref="hangarOutVideo"></div>
                    </div>
                    <div class="flight-name">{{$t('hangar.hangarOut')}}</div>
                    <div class="operation">
                        <i class="el-icon-full-screen" @click="hangarVideoScreen('hangarOut-videoElement')"></i>
                        <i class="el-icon-refresh" @click="hangarVideoRefresh('hangarOut')"></i>
                    </div>
                </div>
            </div>
            <div class="hangar-operate">
                <div class="operate-item">
                    <div class="operate-item-cont">
                        <div class="item-cont-left">
                            <img src="/img/cangmen.png" alt="">
                            <div class="information">
                                <div class="information-name">{{$t('hangar.hatch')}}</div>
                                <div class="information-status">{{hatch(detailsData.hatch)}}</div>
                            </div>
                        </div>
                        <div class="item-cont-right">
                            <el-button size="mini" :loading="hangarOperateLoading.hatch" 
                                @click="hangarControl(1)" v-show="hatchBtn(detailsData.hatch)">
                                {{hatchBtn(detailsData.hatch)}}</el-button>
                        </div>
                    </div>
                </div>
                <div class="operate-item">
                    <div class="operate-item-cont">
                        <div class="item-cont-left">
                            <img src="/img/gzg.png" alt="">
                            <div class="information">
                                <div class="information-name">{{$t('hangar.middlePole')}}</div>
                                <div class="information-status">{{tieRod(detailsData.tieRod)}}</div>
                            </div>
                        </div>
                        <div class="item-cont-right">
                            <el-button size="mini" :loading="hangarOperateLoading.rellout" 
                                @click="hangarControl(3)" v-show="tieRodBtn(detailsData.tieRod)">
                                {{tieRodBtn(detailsData.tieRod)}}</el-button>
                        </div>
                    </div>
                </div>
                <div class="operate-item" v-if="baseData.equipmentNumber != 'ASK-02'">
                    <div class="operate-item-cont">
                        <div class="item-cont-left">
                            <img src="/img/chongdian.png" alt="">
                            <div class="information">
                                <div class="information-name">{{$t('hangar.charge')}}</div>
                                <div class="information-status">{{charge(detailsData.charge)}}</div>
                            </div>
                        </div>
                        <div class="item-cont-right">
                            <el-button size="mini" :loading="hangarOperateLoading.charge" 
                                @click="hangarControl(2)" v-show="chargeBtn(detailsData.charge)">
                                {{chargeBtn(detailsData.charge)}}</el-button>
                        </div>
                    </div>
                </div>
                <div class="operate-item" v-if="baseData.equipmentNumber != 'ASK-02'">
                    <div class="operate-item-cont">
                        <div class="item-cont-left">
                            <img src="/img/kaiguan.png" alt="">
                            <div class="information">
                                <div class="information-name">{{$t('hangar.airVehicle')}}</div>
                                <div class="information-status">{{isOnline(detailsData.isOnline)}}</div>
                            </div>
                        </div>
                        <div class="item-cont-right">
                            <el-button size="mini" :loading="hangarOperateLoading.uav" 
                                @click="hangarControl(4)" v-if="isOnlineBtn(detailsData.isOnline)">
                                {{isOnlineBtn(detailsData.isOnline)}}</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
// import flvjs from 'flv.js';
import {hangarOperate} from "@/api/hangar/index";

export default {
    name: 'hangarDetails',
    props: {
        // 基础数据
        baseData: {
            type: Object,
            default: function() {
                return {}
            }
        },
        // 详情数据
        detailsData: {
            type: Object,
            default: function() {
                return {}
            }
        },
        // 视频数据
        videoData: {
            type: Object,
            default: function() {
                return {}
            }
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            hangarInsidePlayer: null,
            hangarInsideLiveURL: '',
            hangarOutPlayer: null,
            hangarOutLiveURL: '',
            showItemType: 'hangarInformation', // hangarInformation or droneInformation or realTimeVideo
            hangarOperateLoading: {
                hatch: false,
                charge: false,
                rellout: false,
                uav: false
            }
        }
    },
    computed: {
        hangarInsideLiveURLPlay() {
            return this.hangarInsideLiveURL ? true : false;
        },
        hangarOutLiveURLPlay() {
            return this.hangarOutLiveURL ? true : false;
        },
        hatch() {
            return function(data) {
                if (data == 1 || data == 5) {
                    return this.$t('hangar.opening');
                } else if (data == 2) {
                    return this.$t('hangar.opened');
                } else if (data == 3) {
                    return this.$t('hangar.closing');
                } else if (data == 4) {
                    return this.$t('hangar.closed');
                }
                return '--';
            }
        },
        hatchBtn() {
            return function(data) {
                if (data == 1 || data == 5) {
                    this.hangarOperateLoading.hatch = true;
                    return this.$t('hangar.open');
                } else if (data == 2) {
                    this.hangarOperateLoading.hatch = false;
                    return this.$t('hangar.close');
                } else if (data == 3) {
                    this.hangarOperateLoading.hatch = true;
                    return this.$t('hangar.close');
                } else if (data == 4) {
                    this.hangarOperateLoading.hatch = false;
                    return this.$t('hangar.open');
                }
                return '';
            }
        },
        tieRod() {
            return function(data) {
                if (data == 1 || data == 2 || data == 3 || data == 7) {
                    return this.$t('hangar.gathering');
                } else if (data == 4) {
                    return this.$t('hangar.gathered');
                } else if (data == 5) {
                    return this.$t('hangar.expanding');
                } else if (data == 6) {
                    return this.$t('hangar.expanded');
                }
                return '--';
            }
        },
        tieRodBtn() {
            return function(data) {
                if (data == 1 || data == 2 || data == 3 || data == 7) {
                    this.hangarOperateLoading.rellout = true;
                    return this.$t('hangar.gather');
                } else if (data == 4) {
                    this.hangarOperateLoading.rellout = false;
                    return this.$t('hangar.expand');
                } else if (data == 5) {
                    this.hangarOperateLoading.rellout = true;
                    return this.$t('hangar.expand');
                } else if (data == 6) {
                    this.hangarOperateLoading.rellout = false;
                    return this.$t('hangar.gather');
                }
                return '';
            }
        },
        charge() {
            return function(data) {
                if (data == 0) {
                    return this.$t('hangar.uncharged');
                } else if (data == 1) {
                    return this.$t('hangar.charging');
                }
                return '--';
            }
        },
        chargeBtn() {
            return function(data) {
                if (data == 0) {
                    return this.$t('hangar.open');
                } else if (data == 1) {
                    return this.$t('hangar.close');
                }
                return '';
            }
        },
        isOnline() {
            return function(data) {
                if (data == 0) {
                    return this.$t('hangar.notTurned');
                } else if (data == 1) {
                    return this.$t('hangar.alreadyTurned');
                }
                return '--';
            }
        },
        isOnlineBtn() {
            return function(data) {
                if (data == 0) {
                    return this.$t('hangar.powerOn');
                }
                return '';
            }
        }
    },
    watch: {
        'show': {
            immediate: true,
            handler: function(val) {
                if (val) {
                    this.showItemType = 'hangarInformation';
                }
            }
        },
        showItemType(val) {
            if (val == 'realTimeVideo') {
                !this.hangarInsidePlayer && this.initHangarInsideVideo();
                !this.hangarOutPlayer && this.initHangarOutVideo();
            }
        },
        // 'videoData': {
        //     deep: true,
        //     handler: function() {
        //         this.initHangarInsideVideo();
        //         this.initHangarOutVideo();
        //     }
        // }
    },
    created() {
        this.hangarInsidePlayer = null;
        this.hangarInsideLiveURL = '';
        this.hangarOutPlayer = null;
        this.hangarOutLiveURL = '';
        this.showItemType = 'hangarInformation';
    },
    methods: {
        switchTab(type) {
            if (this.showItemType === type) return;
            this.showItemType = type;
        },
        closeDetailsPop() {
            this.$emit('closeDetails');
            this.$emit("update:show", false);
        },
        // 机库内视频
        async initHangarInsideVideo() {
            this.hangarInsideLiveURL = this.videoData.hangarInVideoUrl || '';
            if (this.hangarInsideLiveURL) {
                this.hangarInsidePlayer = await this.initFlvVideo(
                    this.hangarInsidePlayer,
                    // 'hangarInside-videoElement',
                    'hangarInsideVideo',
                    this.hangarInsideLiveURL
                );
                setTimeout(() => {
                    this.hangarInsidePlayer.play(this.hangarInsideLiveURL);
                }, 500);
            }
        },
        // 机库外视频
        async initHangarOutVideo() {
            this.hangarOutLiveURL = this.videoData.hangarOutVideoUrl || '';
            if (this.hangarOutLiveURL) {
                this.hangarOutPlayer = await this.initFlvVideo(
                    this.hangarOutPlayer,
                    // 'hangarOut-videoElement',
                    'hangarOutVideo',
                    this.hangarOutLiveURL
                );
                setTimeout(() => {
                    this.hangarOutPlayer.play(this.hangarOutLiveURL);
                }, 500);
            }
        },
        // 视频全屏
        hangarVideoScreen(id) {
            // let video = document.getElementById(id);
            // if (video.requestFullscreen) { // W3C 规范
            //     video.requestFullscreen().catch(() => {
            //         console.error('无法全屏');
            //     });
            // } else if (video.mozRequestFullScreen) { // Firefox 旧版浏览器
            //     video.mozRequestFullScreen();
            // } else if (video.webkitRequestFullscreen) { // Chrome, Safari 和 Opera
            //     video.webkitRequestFullscreen().catch(() => {
            //         console.error('无法全屏');
            //     });
            // } else if (video.msRequestFullscreen) { // IE 11
            //     video.msRequestFullscreen();
            // }
            if (id == 'hangarInside-videoElement') {
                this.hangarInsidePlayer && this.hangarInsidePlayer.setFullscreen(true);
            } else {
                this.hangarOutPlayer && this.hangarOutPlayer.setFullscreen(true);
            }
        },
        // 视频刷新
        hangarVideoRefresh(type) {
            if (type === 'hangarInside') {
                this.hangarInsideLiveURL = '';
            } else {
                this.hangarOutLiveURL = '';
            }
            setTimeout(async () => {
                if (type === 'hangarInside') {
                    this.hangarInsideLiveURL = this.videoData.hangarInVideoUrl || '';
                    if (this.hangarInsideLiveURL) {
                        this.hangarInsidePlayer = await this.initFlvVideo(
                            this.hangarInsidePlayer,
                            // 'hangarInside-videoElement',
                            'hangarInsideVideo',
                            this.hangarInsideLiveURL
                        );
                        setTimeout(() => {
                            this.hangarInsidePlayer.play(this.hangarInsideLiveURL);
                        }, 500);
                    }
                } else {
                    this.hangarOutLiveURL = this.videoData.hangarOutVideoUrl || '';
                    if (this.hangarOutLiveURL) {
                        this.hangarOutPlayer = await this.initFlvVideo(
                            this.hangarOutPlayer,
                            // 'hangarOut-videoElement',
                            'hangarOutVideo',
                            this.hangarOutLiveURL
                        );
                        setTimeout(() => {
                            this.hangarOutPlayer.play(this.hangarOutLiveURL);
                        }, 500);
                    }
                }
            }, 500);
        },
        // 初始化播放器
        initFlvVideo(hangarVideoPlayer, id, videoUrl) {
            return new Promise((resolve) => {
                this.$nextTick(async () => {
                    if (hangarVideoPlayer) {
                        // hangarVideoPlayer.pause();
                        // hangarVideoPlayer.unload();
                        // hangarVideoPlayer.detachMediaElement();
                        // hangarVideoPlayer = null;
                        await hangarVideoPlayer.destroy();
                        hangarVideoPlayer = null;
                    }
                    // if (flvjs.isSupported()) {
                    //     let videoElement = document.getElementById(id);
                    //     let videoPlayer = flvjs.createPlayer({
                    //         type: "flv",
                    //         isLive: true,
                    //         hasAudio: false,
                    //         url: videoUrl
                    //     });
                    //     videoPlayer.attachMediaElement(videoElement);
                    //     videoPlayer.load();
                    //     videoPlayer.play();
                    //     resolve(videoPlayer);
                    // }
                    let options = {};
                    let videoPlayer = new window.Jessibuca(
                        Object.assign(
                            {
                                container: this.$refs[id],
                                autoWasm: true,
                                controlAutoHide: true,
                                debug: false,
                                decoder: '/libs/Jessibuca/decoder.js',
                                hasAudio: false,
                                videoBuffer: 0.1,
                                isResize: false,
                                useWCS: true,
                                useMSE: false,
                                text: '',
                                loadingText: '',
                                supportDblclickFullscreen: true,
                                showBandwidth: false,
                                forceNoOffscreen: false,
                                isNotMute: false,
                                timeout: 10
                            },
                            options
                        )
                    );
                    resolve(videoPlayer);
                });
            });
        },
        // 机库操控
        hangarControl(type) {
            if (JSON.stringify(this.detailsData) == '{}') {
                this.$message.error(this.$t('hangar.noControlData'));
                return;
            }
            let operateType = '';
            let loadingType = '';
            if (type == 1) {
                // 舱门开闭（open 打开 close 关闭）
                let hatch = this.detailsData.hatch;
                if (hatch == 1 || hatch == 5) {
                    operateType = 'open';
                } else if (hatch == 2) {
                    operateType = 'close';
                } else if (hatch == 3) {
                    operateType = 'close';
                } else if (hatch == 4) {
                    operateType = 'open';
                }
                loadingType = 'hatch';
            } else if (type == 2) {
                // 充电开闭（start 开启 stop 关闭）
                operateType = this.detailsData.charge == 1 ? 'stop' : 'start';
                loadingType = 'charge';
            } else if (type == 3) {
                // 归中杆开闭（withdraw 展开 rellout 合拢）
                let tieRod = this.detailsData.tieRod;
                if (tieRod == 1 || tieRod == 2 || tieRod == 3 || tieRod == 7) {
                    operateType = 'rellout';
                } else if (tieRod == 4) {
                    operateType = 'withdraw';
                } else if (tieRod == 5) {
                    operateType = 'withdraw';
                } else if (tieRod == 6) {
                    operateType = 'rellout';
                }
                loadingType = 'rellout';
            } else if (type == 4) {
                // 飞行器开闭
                loadingType = 'uav';
            }
            if (operateType && this.baseData.serialNumber) {
                let params = {
                    sn: this.baseData.serialNumber,
                    switchType: type,
                    type: operateType
                }
                if (type == 2 || type == 4) {
                    this.hangarOperateLoading[loadingType] = true;
                }
                hangarOperate(params).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success(this.$t('requestSuccessText'));
                    } else {
                        this.$message.error(this.$t('requestErrorText'));
                    }
                }).catch(err => {
                    this.$message.error(err);
                }).finally(() => {
                    if (type == 2 || type == 4) {
                        this.hangarOperateLoading[loadingType] = false;
                    }
                });
            }
        }
    },
    async beforeDestroy() {
        if (this.hangarInsidePlayer) {
            // this.hangarInsidePlayer.pause();
            // this.hangarInsidePlayer.unload();
            // this.hangarInsidePlayer.detachMediaElement();
            await this.hangarInsidePlayer.destroy();
        }
        this.hangarInsidePlayer = null;
        this.hangarInsideLiveURL = '';
        if (this.hangarOutPlayer) {
            // this.hangarOutPlayer.pause();
            // this.hangarOutPlayer.unload();
            // this.hangarOutPlayer.detachMediaElement();
            await this.hangarOutPlayer.destroy();
        }
        this.hangarOutPlayer = null;
        this.hangarOutLiveURL = '';
        this.showItemType = 'hangarInformation';
    }
}
</script>

<style lang="scss" scoped>
.hangarDetailsDialog {
    ::v-deep .el-dialog {
        background-color: transparent;
        .el-dialog__header {
            display: block;
            padding-bottom: 0;
            border-bottom: 1px solid #36383D;
            background-color: rgba(12, 12, 12, 0.9);
            .dialog_title {
                display: flex;
                font-weight: bold;
                font-size: 15px;
                color: #FFFFFF;
                .dialog_title-item {
                    padding-bottom: 15px;
                    margin-left: 35px;
                    border-radius: 2px;
                    border-bottom: 2px solid transparent;
                    cursor: pointer;
                    &:first-child {
                        margin-left: 0;
                        cursor: pointer;
                    }
                    &.active {
                        color: #265EDD;
                        border-bottom: 2px solid #265EDD;
                    }
                }
            }
            .el-dialog__headerbtn .el-dialog__close {
                color: #ffffff;
            }
        }
        .el-dialog__body {
            padding: 0;
            background: rgba(12, 12, 12, 0.9);
            .content {
                max-height: 480px;
                overflow: auto;
                .hangarInformation,.droneInformation,.realTimeVideo {
                    border-bottom: 1px solid #36383D;
                }
                .top-title {
                    padding: 15px 20px 0 20px;
                    font-family: MicrosoftYaHeiUI;
                    font-size: 15px;
                    color: #ffffff;
                }
                .information-top {
                    display: flex;
                    padding: 15px 20px;
                    border-bottom: 1px solid #36383D;
                    .top-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100px;
                        height: 100px;
                        border-radius: 3px;
                        background: #D8D8D7;
                        .jiku {
                            width: 38px;
                            height: 40px;
                            vertical-align: middle;
                        }
                        .wurenji {
                            width: 84px;
                            height: 84px;
                            vertical-align: middle;
                        }
                    }
                    .top-msg {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        margin-left: 15px;
                        .top-msg_list {
                            display: flex;
                            flex: 1;
                            align-items: center;
                            .msg_list-item {
                                display: flex;
                                flex: 1;
                                align-items: center;
                                font-family: MicrosoftYaHeiUI;
                                font-size: 14px;
                                .list-item_name {
                                    width: 100px;
                                    min-width: 100px;
                                    color: #CCCCCC;
                                }
                                .list-item_msg {
                                    color: #ffffff;
                                    margin-left: 10px;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 1;
                                    text-overflow: ellipsis;
                                    word-break: break-all;
                                    &.free {
                                        color: #00BB1C;
                                    }
                                    &.working {
                                        color: #265EDD;
                                    }
                                    &.normal {
                                        color: #00BB1C;
                                    }
                                    &.abnormal {
                                        color: #FF0000;
                                    }
                                }
                            }
                        }
                    }
                }
                .information-bottom {
                    display: flex;
                    flex-direction: column;
                    padding: 15px 20px;
                    .bottom-list {
                        display: flex;
                        flex: 1;
                        flex-wrap: wrap;
                        margin-top: 12px;
                        &:first-child {
                            margin-top: 0;
                        }
                        .bottom-list_item {
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            align-items: center;
                            font-family: MicrosoftYaHeiUI;
                            font-size: 14px;
                            .list_item-name {
                                color: #CCCCCC;
                            }
                            .list_item-msg {
                                font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI;
                                font-weight: bold;
                                font-size: 18px;
                                color: #FFFFFF;
                                margin-top: 15px;
                            }
                        }
                    }
                }
                .realTimeVideo {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 15px 15px 0;
                    .video_item {
                        width: 0;
                        min-width: 400px;
                        max-width: 400px;
                        flex: 1;
                        height: 230px;
                        margin-left: 20px;
                        background-color: #1e222a;
                        margin-bottom: 20px;
                        position: relative;
                        &:nth-child(2n + 1) {
                            margin-left: 0;
                        }
                        .video_cont {
                            width: 100% !important;
                            height: 100% !important;
                            .videoElement {
                                width: 100% !important;
                                height: 100% !important;
                            }
                        }
                        .flight-name {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            font-family: MicrosoftYaHeiUI;
                            font-size: 14px;
                            color: #FFFFFF;
                            background-color: rgba(0, 0, 0, 0.7);
                            padding: 6px 15px;
                            border-bottom-right-radius: 5px;
                            border-bottom-left-radius: 5px;
                        }
                        .operation {
                            display: none;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            z-index: 100;
                            background: rgba(0, 0, 0, 0.4);
                            text-align: center;
                            padding: 5px 0;
                            i {
                                font-size: 22px;
                                color: #ffffff;
                                margin: 0 10px;
                                cursor: pointer;
                            }
                        }
                        &:hover .operation {
                            display: block;
                        }
                    }
                }
                .hangar-operate {
                    display: flex;
                    padding: 15px 20px;
                    .operate-item {
                        width: 0;
                        display: flex;
                        flex: 1;
                        justify-content: center;
                        margin-left: 15px;
                        &:first-child {
                            margin-left: 0;
                        }
                        .operate-item-cont {
                            display: flex;
                            align-items: center;
                            border-radius: 4px;
                            padding: 6px 10px;
                            background: #333333;
                            .item-cont-left {
                                display: flex;
                                align-items: center;
                                img {
                                    width: 20px;
                                    height: 20px;
                                }
                                .information {
                                    margin-left: 8px;
                                    .information-name {
                                        font-size: 12px;
                                        color: #AAAAAA;
                                        margin-bottom: 5px;
                                    }
                                    .information-status {
                                        font-size: 12px;
                                        color: #ffffff;
                                    }
                                }
                            }
                            .item-cont-right {
                                margin-left: 20px;
                                .el-button {
                                    padding: 5px 8px;
                                    font-size: 12px;
                                    color: #ffffff;
                                    background: #666666;
                                    border: 1px solid #ffffff;
                                    &:hover {
                                        color: #000000;
                                        background: #ffffff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
video::-webkit-media-controls-enclosure {
    /* 禁用播放器控制栏的样式 */
    display: none !important;
}
</style>