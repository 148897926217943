var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "hangarDetailsDialog",
      attrs: {
        visible: _vm.show,
        width: "870px",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        top: "15vh",
        "before-close": _vm.closeDetailsPop,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog_title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog_title-item",
              class: { active: _vm.showItemType === "hangarInformation" },
              on: {
                click: function ($event) {
                  return _vm.switchTab("hangarInformation")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("hangar.hangarInformation")))]
          ),
          _c(
            "div",
            {
              staticClass: "dialog_title-item",
              class: { active: _vm.showItemType === "droneInformation" },
              on: {
                click: function ($event) {
                  return _vm.switchTab("droneInformation")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("hangar.droneInformation")))]
          ),
          _c(
            "div",
            {
              staticClass: "dialog_title-item",
              class: { active: _vm.showItemType === "realTimeVideo" },
              on: {
                click: function ($event) {
                  return _vm.switchTab("realTimeVideo")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("hangar.realTimeVideo")))]
          ),
        ]
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showItemType === "hangarInformation",
                expression: "showItemType === 'hangarInformation'",
              },
            ],
            staticClass: "hangarInformation",
          },
          [
            _c("div", { staticClass: "top-title" }, [
              _vm._v(
                _vm._s(_vm.$t("hangar.hangarName")) +
                  "：" +
                  _vm._s(_vm.baseData.name || "--")
              ),
            ]),
            _c("div", { staticClass: "information-top" }, [
              _c(
                "div",
                { staticClass: "top-img" },
                [
                  _vm.baseData.pics
                    ? _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          src: _vm.baseData.pics,
                          fit: "contain",
                          "preview-src-list": [_vm.baseData.pics],
                        },
                      })
                    : _c("img", {
                        staticClass: "jiku",
                        attrs: { src: "/img/jiku.png", alt: "" },
                      }),
                ],
                1
              ),
              _c("div", { staticClass: "top-msg" }, [
                _c("div", { staticClass: "top-msg_list" }, [
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("hangar.model"))),
                    ]),
                    _c("div", { staticClass: "list-item_msg" }, [
                      _vm._v(_vm._s(_vm.baseData.equipmentNumber || "--")),
                    ]),
                  ]),
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("mission.team"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "list-item_msg",
                        attrs: { title: _vm.baseData.deptNames },
                      },
                      [_vm._v(_vm._s(_vm.baseData.deptNames || "--"))]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "top-msg_list" }, [
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("hangar.workingStatus"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "list-item_msg free",
                        class: [_vm.baseData.status == 1 ? "free" : "working"],
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.baseData.status == 1
                                ? _vm.$t("idle")
                                : _vm.$t("working")
                            )
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("hangar.creator"))),
                    ]),
                    _c("div", { staticClass: "list-item_msg" }, [
                      _vm._v(_vm._s(_vm.baseData.createBy || "--")),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "top-msg_list" }, [
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("hangar.serialNumber"))),
                    ]),
                    _c("div", { staticClass: "list-item_msg" }, [
                      _vm._v(_vm._s(_vm.baseData.serialNumber || "--")),
                    ]),
                  ]),
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("noFlyZone.createdTime"))),
                    ]),
                    _c("div", { staticClass: "list-item_msg" }, [
                      _vm._v(_vm._s(_vm.baseData.createTime || "--")),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "top-msg_list" }, [
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("remark"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "list-item_msg",
                        attrs: { title: _vm.baseData.remark },
                      },
                      [_vm._v(_vm._s(_vm.baseData.remark || "--"))]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "information-bottom" }, [
              _c("div", { staticClass: "bottom-list" }, [
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.cabinTemperature"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.inTemperature || 0) + "°C"),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.ambientTemperature"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.outTemperature || 0) + "°C"),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.windSpeed"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.windSpeed || 0) + "m/s"),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.lightStripSwitch"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.detailsData.lightStrip == 1
                            ? _vm.$t("openText")
                            : _vm.$t("closeText")
                        )
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "bottom-list" }, [
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.flooded"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(
                          _vm.detailsData.weatherFlood == 1
                            ? _vm.$t("correct")
                            : _vm.$t("deny")
                        )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.cumulativeRainfall"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.rainfall || 0) + "mm"),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.alternateLon"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.alternateLongitude || "--")),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.alternateLat"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.alternateLatitude || "--")),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showItemType === "droneInformation",
                expression: "showItemType === 'droneInformation'",
              },
            ],
            staticClass: "droneInformation",
          },
          [
            _c("div", { staticClass: "top-title" }, [
              _vm._v(
                _vm._s(_vm.$t("hangar.droneName")) +
                  "：" +
                  _vm._s(_vm.baseData.uavName || "--")
              ),
            ]),
            _c("div", { staticClass: "information-top" }, [
              _c("div", { staticClass: "top-img" }, [
                _c("img", {
                  staticClass: "wurenji",
                  attrs: { src: "/img/wurenji.png", alt: "" },
                }),
              ]),
              _c("div", { staticClass: "top-msg" }, [
                _c("div", { staticClass: "top-msg_list" }, [
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("hangar.model"))),
                    ]),
                    _c("div", { staticClass: "list-item_msg" }, [
                      _vm._v(_vm._s(_vm.baseData.uavEquipmentNumber || "--")),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "top-msg_list" }, [
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("hangar.workingStatus"))),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "list-item_msg free",
                        class: [_vm.baseData.status == 1 ? "working" : "free"],
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(
                              _vm.baseData.status == 1
                                ? _vm.$t("working")
                                : _vm.$t("idle")
                            )
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "top-msg_list" }, [
                  _c("div", { staticClass: "msg_list-item" }, [
                    _c("div", { staticClass: "list-item_name" }, [
                      _vm._v(_vm._s(_vm.$t("hangar.serialNumber"))),
                    ]),
                    _c("div", { staticClass: "list-item_msg" }, [
                      _vm._v(_vm._s(_vm.baseData.uavSerialNumber || "--")),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "information-bottom" }, [
              _c("div", { staticClass: "bottom-list" }, [
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.flightDuration"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.flyTotal || 0) + "h"),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.chargeCount"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.chargeTimes || 0)),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.batteryTemperature"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.batteryTemp || 0) + "°C"),
                  ]),
                ]),
                _c("div", { staticClass: "bottom-list_item" }, [
                  _c("div", { staticClass: "list_item-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.electricityLevel"))),
                  ]),
                  _c("div", { staticClass: "list_item-msg" }, [
                    _vm._v(_vm._s(_vm.detailsData.electricity || 0) + "%"),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showItemType === "realTimeVideo",
                expression: "showItemType === 'realTimeVideo'",
              },
            ],
            staticClass: "realTimeVideo",
          },
          [
            _c("div", { staticClass: "video_item" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: !_vm.hangarInsideLiveURLPlay,
                      expression: "!hangarInsideLiveURLPlay",
                    },
                  ],
                  staticClass: "video_cont",
                  attrs: {
                    id: "hangar-video",
                    "element-loading-text": _vm.$t("resourceLoading"),
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(0, 0, 0, 1)",
                  },
                },
                [
                  _vm.hangarInsideLiveURLPlay
                    ? _c("div", {
                        ref: "hangarInsideVideo",
                        staticClass: "videoElement",
                      })
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "flight-name" }, [
                _vm._v(_vm._s(_vm.$t("hangar.hangarInside"))),
              ]),
              _c("div", { staticClass: "operation" }, [
                _c("i", {
                  staticClass: "el-icon-full-screen",
                  on: {
                    click: function ($event) {
                      return _vm.hangarVideoScreen("hangarInside-videoElement")
                    },
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-refresh",
                  on: {
                    click: function ($event) {
                      return _vm.hangarVideoRefresh("hangarInside")
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "video_item" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: !_vm.hangarOutLiveURLPlay,
                      expression: "!hangarOutLiveURLPlay",
                    },
                  ],
                  staticClass: "video_cont",
                  attrs: {
                    id: "hangar-video",
                    "element-loading-text": _vm.$t("resourceLoading"),
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(0, 0, 0, 1)",
                  },
                },
                [
                  _vm.hangarOutLiveURLPlay
                    ? _c("div", {
                        ref: "hangarOutVideo",
                        staticClass: "videoElement",
                      })
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "flight-name" }, [
                _vm._v(_vm._s(_vm.$t("hangar.hangarOut"))),
              ]),
              _c("div", { staticClass: "operation" }, [
                _c("i", {
                  staticClass: "el-icon-full-screen",
                  on: {
                    click: function ($event) {
                      return _vm.hangarVideoScreen("hangarOut-videoElement")
                    },
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-refresh",
                  on: {
                    click: function ($event) {
                      return _vm.hangarVideoRefresh("hangarOut")
                    },
                  },
                }),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "hangar-operate" }, [
          _c("div", { staticClass: "operate-item" }, [
            _c("div", { staticClass: "operate-item-cont" }, [
              _c("div", { staticClass: "item-cont-left" }, [
                _c("img", { attrs: { src: "/img/cangmen.png", alt: "" } }),
                _c("div", { staticClass: "information" }, [
                  _c("div", { staticClass: "information-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.hatch"))),
                  ]),
                  _c("div", { staticClass: "information-status" }, [
                    _vm._v(_vm._s(_vm.hatch(_vm.detailsData.hatch))),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "item-cont-right" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hatchBtn(_vm.detailsData.hatch),
                          expression: "hatchBtn(detailsData.hatch)",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        loading: _vm.hangarOperateLoading.hatch,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.hangarControl(1)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.hatchBtn(_vm.detailsData.hatch))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "operate-item" }, [
            _c("div", { staticClass: "operate-item-cont" }, [
              _c("div", { staticClass: "item-cont-left" }, [
                _c("img", { attrs: { src: "/img/gzg.png", alt: "" } }),
                _c("div", { staticClass: "information" }, [
                  _c("div", { staticClass: "information-name" }, [
                    _vm._v(_vm._s(_vm.$t("hangar.middlePole"))),
                  ]),
                  _c("div", { staticClass: "information-status" }, [
                    _vm._v(_vm._s(_vm.tieRod(_vm.detailsData.tieRod))),
                  ]),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "item-cont-right" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.tieRodBtn(_vm.detailsData.tieRod),
                          expression: "tieRodBtn(detailsData.tieRod)",
                        },
                      ],
                      attrs: {
                        size: "mini",
                        loading: _vm.hangarOperateLoading.rellout,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.hangarControl(3)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.tieRodBtn(_vm.detailsData.tieRod))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm.baseData.equipmentNumber != "ASK-02"
            ? _c("div", { staticClass: "operate-item" }, [
                _c("div", { staticClass: "operate-item-cont" }, [
                  _c("div", { staticClass: "item-cont-left" }, [
                    _c("img", {
                      attrs: { src: "/img/chongdian.png", alt: "" },
                    }),
                    _c("div", { staticClass: "information" }, [
                      _c("div", { staticClass: "information-name" }, [
                        _vm._v(_vm._s(_vm.$t("hangar.charge"))),
                      ]),
                      _c("div", { staticClass: "information-status" }, [
                        _vm._v(_vm._s(_vm.charge(_vm.detailsData.charge))),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "item-cont-right" },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.chargeBtn(_vm.detailsData.charge),
                              expression: "chargeBtn(detailsData.charge)",
                            },
                          ],
                          attrs: {
                            size: "mini",
                            loading: _vm.hangarOperateLoading.charge,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.hangarControl(2)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.chargeBtn(_vm.detailsData.charge))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.baseData.equipmentNumber != "ASK-02"
            ? _c("div", { staticClass: "operate-item" }, [
                _c("div", { staticClass: "operate-item-cont" }, [
                  _c("div", { staticClass: "item-cont-left" }, [
                    _c("img", { attrs: { src: "/img/kaiguan.png", alt: "" } }),
                    _c("div", { staticClass: "information" }, [
                      _c("div", { staticClass: "information-name" }, [
                        _vm._v(_vm._s(_vm.$t("hangar.airVehicle"))),
                      ]),
                      _c("div", { staticClass: "information-status" }, [
                        _vm._v(_vm._s(_vm.isOnline(_vm.detailsData.isOnline))),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "item-cont-right" },
                    [
                      _vm.isOnlineBtn(_vm.detailsData.isOnline)
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "mini",
                                loading: _vm.hangarOperateLoading.uav,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.hangarControl(4)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    _vm.isOnlineBtn(_vm.detailsData.isOnline)
                                  )
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }