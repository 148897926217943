<template>
    <!-- 基站弹窗 -->
    <div :id="id ? 'baseStation-pop-' + id : 'baseStation-pop'" class="baseStation-pop" v-show="isShow">
        <div class="tip-triangle"></div>
        <div class="tip-item" v-for="item in tipItems" :key="item.label">
            <label>{{$t(item.label)}}：</label>
            <p>{{detailData[item.key]}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'baseStation-pop',
    props: {
        id: {
            type: String,
            default: ''
        },
        detailData: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    data() {
        return {
            isShow: false,
            tipItems: [
                {label: 'baseStation.name', key: 'name'},
                {label: 'baseStation.address', key: 'address'},
                {label: 'baseStation.baseStationIp', key: 'baseStationIp'},
                {label: 'baseStation.edgeServerAdHocIp', key: 'edgeServerAdHocIp'},
                {label: 'baseStation.edgeServerLanIp', key: 'edgeServerLanIp'}
            ]
        }
    },
    methods: {
        showPop() {
            this.isShow = true;
        },
        hidePop() {
            this.isShow = false;
        }
    }
}
</script>

<style lang="scss" scoped>
.baseStation-pop {
    position: absolute;
    // bottom: -1000px;
    // right: -1000px;
    width: 230px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
    .tip-triangle {
        position: absolute;
        box-sizing: border-box;
        border: 8px solid;
        border-color: transparent rgba(0, 0, 0, 0.8) rgba(0, 0, 0, 0.8) transparent;
        left: 50%;
        top: 100%;
        transform: translateX(-50%) translateY(-51%) rotateZ(45deg);
    }
    .tip-item {
        display: flex;
        font-size: 14px;
        margin-bottom: 6px;
        &:last-child {
            margin-bottom: 0;
        }
        label {
            width: 120px;
        }
        p {
            flex: 1;
        }
    }
}
</style>