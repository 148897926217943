<script>
import {getAirportList} from '@/api/airportManager/index';
import userAirportHook from '@/components/cesium/js/hooks/airportHook';

export default {
    data() {
        return {
            // 机场数据
            airportList: [],
        }
    },
    methods: {
        // 初始化机场纯展示图层（与机场管理图层区分开）
        initAirportShowLayer() {
            this.$options.airportHook = userAirportHook();
            this.$options.airportHook.setMap({map3d: this.$options.map3d, layerID: 'airportShowLayer'});
        },
        // 获取机场数据并绘制
        getAirportData() {
            this.clearAirportShowLayer();
            getAirportList().then(res => {
                if (res.data.code == 200) {
                this.airportList = res.data.data || [];
                this.$options.airportHook.handlingAirportData({data: this.airportList});
                }
            });
        },
        // 清除机场显示图层
        clearAirportShowLayer() {
            this.$options.airportHook.clearRenderLayer();
        }
    },
    beforeDestroy() {
        this.$options.airportHook && this.$options.airportHook.clearAirport();
        this.$options.airportHook = null;
    }
}
</script>