import BaseLayer from "../BaseLayer";
import {HangarPoint, HangarShowPoint} from "../node/bussiness/hangar/hangarPoint";

const userHangarHook = () => {
    let map3dCollection, renderLayer;
    return (function() {
        // 初始化地图图层实例
        const setMap = (options) => {
            const {map3d, layerID} = options;
            map3dCollection = map3d;
            renderLayer = new BaseLayer({name: layerID || "hangarLayer"}); // 初始化绘制层
            map3dCollection.layerManager.add(renderLayer); // 添加到地图中
        };
        // 处理绘制图层数据并开始绘制
        const handlingHangarData = (options) => {
            const {data, type} = options;
            data && data.map(t => {
                if (t.alternateLongitude * 1 && t.alternateLatitude * 1) {
                    const id = t.id;
                    const sn = t.serialNumber;
                    // 绘制之前先查找一次有无当前id的实体，若有则不需要进行绘制
                    let entity = getEntityById(id);
                    const point = [t.alternateLongitude * 1, t.alternateLatitude * 1];
                    const position = Cesium.Cartesian3.fromDegrees(...point);
                    if (entity) {
                        // 更改实体
                        changeHangarPoint({id, sn, name: t.name, position, show: t.visibility ? true : false});
                    } else {
                        // 绘制实体
                        createHangarPoint({id, sn, name: t.name, position, show: t.visibility ? true : false}, type);
                    }
                }
            });
        };
        // 绘制机库实体
        const createHangarPoint = (options, type) => {
            let layer = type === 'select' ? new HangarPoint(options) : new HangarShowPoint(options);
            renderLayer && renderLayer.addNode(layer);
        };
        // 更改机库实体
        const changeHangarPoint = (options) => {
            const node = renderLayer.getNodeById(options.id);
            if (!node) {
                throw "未在地图中找到应对entity, 无法更新";
            }
            node.name = options.name;
            node.position = options.position;
            node.entity.sn = options.sn;
        };
        // 通过id获取对应实体
        const getEntityById = (id) => {
            if (!id) return;
            return renderLayer.getById(id);
        };
        // 获取当前绘制层
        const getRenderLayer = () => {
            return renderLayer;
        };
        // 单独清除当前renderLayer层的渲染实体
        const clearRenderLayer = () => {
            renderLayer && renderLayer.clear();
        };
        // 清除地图实例，图层实体
        const clearHangar = () => {
            renderLayer && renderLayer.clear();
            map3dCollection.layerManager.remove(renderLayer, true);
            renderLayer = null;
            map3dCollection = null;
        };
        return {
            setMap,
            handlingHangarData,
            createHangarPoint,
            changeHangarPoint,
            getEntityById,
            getRenderLayer,
            clearRenderLayer,
            clearHangar
        };
    })();
};

export default userHangarHook;