<script>
import {getBasestationList, getBasestationDetail} from "@/api/map/index";
import userBaseStationHook from '@/components/cesium/js/hooks/baseStationHook';
import BaseStationPop from "../components/baseStationPop.vue";

export default {
    components: { BaseStationPop },
    data() {
        return {
            cusId: '',
            // 基站数据
            basestationList: [],
            baseStationDetail: {}
        }
    },
    methods: {
        // 初始化基站图层
        initBasestationShowLayer(cusId) {
            this.cusId = cusId || '';
            this.$options.baseStationHook = userBaseStationHook();
            this.$options.baseStationHook.setMap({map3d: this.$options.map3d, layerID: 'baseStationLayer'});
        },
        // 获取基站数据并绘制
        getBasestationData() {
            let getBasestationData = () => {
                getBasestationList().then(res => {
                    if (res.data.code == 200) {
                        this.basestationList = res.data.data || [];
                        this.$options.baseStationHook.handlingBaseStationData({data: this.basestationList});
                        this.$options.baseStationHook.addMouseMove(this.baseStationMouseBack);
                    }
                });
            }
            if (!this.firstPath) {
                getBasestationData();
                return;
            }
            const secondLevel = this.$route.matched[1];
            switch (this.firstPath) {
                case '/mission-center':
                    if (secondLevel.name === 'project-manager') getBasestationData();
                    break;
                case '/oil-gas':
                    if (secondLevel.name === 'OG-Inspection') getBasestationData();
                    break;
            }
        },
        // 基站图标悬浮事件回调
        baseStationMouseBack(data) {
            if (data.type === 'removePop') {
                this.$options.baseStationHook.rmoveAllPop(this.$refs.BaseStationPop.hidePop);
                return;
            }
            if (!data || !data.id) return;
            getBasestationDetail({id: data.id}).then(res => {
                if (res.data.code == 200) {
                    this.baseStationDetail = res.data.data || {};
                    this.$options.baseStationHook.rmoveAllPop(this.$refs.BaseStationPop.hidePop);
                    this.$options.baseStationHook.createdPop({
                        id: this.cusId ? `baseStation-pop-${this.cusId}` : 'baseStation-pop',
                        entityId: res.data.data.id,
                        event: data.event,
                        positions: res.data.data.content ? res.data.data.content.coordinates : [0, 0],
                        x: data.x || 0,
                        y: data.y || 0,
                        callback: this.$refs.BaseStationPop.showPop
                    });
                }
            });
        }
    },
    beforeDestroy() {
        this.$options.baseStationHook && this.$options.baseStationHook.clearBaseStation();
        this.$options.baseStationHook = null;
    }
}
</script>